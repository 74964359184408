<template>
  <div :id="localId" class="svg_wrapper"></div>
</template>

<script>

import mx_Core from './includes/mixins/zMixins'        
import * as d3 from 'd3'
export default {
  name: "j-Skyline_Milestone",
  mixins: [mx_Core],
  props: {
    id: String,
    modeExport: Boolean,
    getSelectItems: String,  

    FilterValues: { type: Object, default: () => ({}) },
    ColumnProps: { type: Array, default: () => ([]) },
    Canvas: { type: Object, default: () => ({}) },
    Text: { type: Object, default: () => ({}) },
    Note: { type: Object, default: () => ({}) },
    DataItems: { type: Array, default: () => ([]) },
    Queries: { type: Object, default: () => ({}) },
  },

  created() {
    this.localId = `${this.id || 'j-chart-custom'}__${this.safeId('')}`
    // console.log('this.id:', this.localId)

  },

  mounted() {
    this.register(this.draw)
  },

  methods: {
    draw() {
      if (!this.ready2draw) {
        return
      }

      this.clear()
      this.setDefaultValues()
      this.set_Styles()
      this.drawCanvas()
      // this.drawNotes()
      // this.drawTexts()
      
      this.drawing() // <------ Drawing Start
      
      this.drawNotes()
      this.drawTexts()
      
      this.complete()
    },

    drawing() {

      this.SkylineCharts = this.svg
      .append('g')
      .attr('id', 'skyline_chart_area_fc')
      .attr('transform', `translate(0.5, 0.5)`)

      this.draw_Header()
      this.draw_Timeline()
      this.draw_SkylineCharts()

      // for export pdf
      this.getItems = this.getSelectItems ? JSON.parse(this.getSelectItems) : {}
      if(this.getSelectItems){
        if(this.getItems) {
          //if(this.getItems.pkgStatus) this.legends.pkgStatus = this.getItems.pkgStatus
          if(this.getItems.commStatus) this.legends.commStatus = this.getItems.commStatus
          if(this.getItems.sheetStatus) this.legends.sheetStatus = this.getItems.sheetStatus
          if(this.getItems.punchStatus) this.legends.punchStatus = this.getItems.punchStatus
          
          // if(this.getItems.searchInput) {
          //   this.search.input = this.getItems.searchInput
          // }

          this.draw_Legends()
          if(this.getItems.filterStatus) this.filtering_Search()
          this.draw_Options()
          // if(this.getItems.searchStatus) this.call_Search(this.search.input)
          this.draw_Information()

        }
      } else {
        this.draw_Legends()
        this.draw_Options()
        this.draw_Information()
      }

    },

  }
}

</script>

<style lang="scss" scoped>
</style>