import moment from 'moment'
import * as d3 from 'd3'

export default {
  methods: {

    draw_Chart_Controller() {

      this.Area_Chart = this.svg
      .append('g')
      .attr('id', `Area_Chart`)
      .attr('transform', `translate(${this.canvas.padding.left+.5}, ${this.canvas.padding.top+.5})`)
      .attr('font-family', 'roboto')

      let width = (this.barWidth-5)/2
      let x = 2 - this.barWidth
      let chart = this.DiscType.find(f=> f.ITEM == 'OV')
      let data = this.rawData.filter(f=> f.ITEM == chart.ITEM && f.DISC == chart.DISC && f.ITR_TYPE == chart.ITR_TYPE)

      // Bar Chart
      this.draw_BarChart('PLAN', {
        data:     data,
        scale:    this.scale.Primary,
        x:        x,
        width:    width,
        color:    '#bcbcbc',
        opacity:  .3,
      })
      
      this.draw_BarChart('ACTUAL', {
        data:     data.filter(f=>f.CDATE <= this.timeline.cutoff),
        scale:    this.scale.Primary,
        x:        x + width+1 ,
        width:    width,
        color:    this.barColor[`${chart.ITEM}`],
        opacity:  .99,
      })
      x += width + 1

      
      // Line Chart
      this.draw_LineChart('PLAN', {
        data:     data,
        scale:    this.scale.Secondary, 
        color:    '#bcbcbc',
        opacity:  1,
        x:        this.barWidth / 2,
      })

      this.draw_LineChart('ACTUAL', {
        data:     data.filter(f=>f.CDATE <= this.timeline.cutoff),
        scale:    this.scale.Secondary, 
        color:    this.barColor[`${chart.ITEM}`],
        opacity:  .8,
        x:        this.barWidth / 2,
      })
    },


    draw_BarChart(sch, cht) {
      let this_ = this

      let cols_ = sch == 'PLAN' ? 'PL_' : ''

      let BarChart = this.Area_Chart
      .append('g')
      .attr('transform', `translate(0,0)`)
      .attr('opacity', cht.opacity)

      BarChart
      .selectAll(`rect_`)
      .data(cht.data)
      .enter()
      .append('rect')
      .attr('id', (d,i)=> `BAR_CLOSED_${cols_ + i}`)
      .attr('x', d => {
        return this.timeline.scale(moment(moment(d.CDATE).format('YYYY-MM-DD 23:59:59')).toDate()) + cht.x
      })
      .attr('y', d => cht.scale(d[`${cols_}CLOSED_WK`]))
      .attr('height', d => this.canvas.chart.height - cht.scale(d[`${cols_}CLOSED_WK`]))
      .attr('width', cht.width)
      .attr('fill', cht.color)
      .attr('stroke', '#bcbcbc').attr('stroke-width', 1).attr('stroke-opacity', .2)
      
      // BarChart
      // .selectAll(`text_`)
      // .data(cht.data)
      // .enter()
      // .append('text')
      // .attr('id', (d,i)=> `VALUE_CLOSED_${cols_ + i}`)
      // .attr('x', d => {
      //   return this.timeline.scale(moment(moment(d.CDATE).format('YYYY-MM-DD 23:59:59')).toDate()) + cht.x + (cht.width/2)
      // })
      // .attr('y', d => cht.scale(d[`${cols_}CLOSED_WK`]) - 5)
      // .attr('font-size', 8).attr('text-anchor', 'middle')
      // .attr('fill', '#757575').attr('opacity', d=> d[`${cols_}CLOSED_WK`] == 0 ? 0.3 : 1)
      // .text(d => d[`${cols_}CLOSED_WK`])
      // .each(function(d) {
      //   d3.select(this).call(this_.call_History, {cDate: d.CDATE, status: 'CLOSE'})
      // })
    },

    

    draw_LineChart(sch, cht) {

      let cols_ = sch == 'PLAN' ? 'PL_' : ''

      let lineFunc = d3.line()
      .x(d => this.timeline.scale(moment(moment(d.CDATE).format('YYYY-MM-DD 23:59:59')).toDate())-cht.x)
      .y(d => cht.scale(d[`${cols_}REMAIN_CUM`]))

      let final = {date: 0, value: 0}
      let lineChart = this.Area_Chart
      .append('g')
      .attr('transform', `translate(0,0)`)
      .attr('opacity', cht.opacity)

      lineChart
      .append('path')
      .attr('id', (d,i)=> `${cols_}LINE_CHART`)
      .attr('d', lineFunc(cht.data))
      .style("stroke-dasharray", sch == 'PLAN' ? (2,2) : (0,0))
      .style('stroke-width', sch == 'PLAN' ? 1 : 2)
      .style('stroke', cht.color)
      .style('fill', 'none')

      lineChart
      .selectAll(`circle_`)
      .data(cht.data)
      .enter()
      .append('circle')
      .attr('id', (d,i)=> `${cols_}NODE_${i}`)
      .attr('cx', d => this.timeline.scale(moment(moment(d.CDATE).format('YYYY-MM-DD 23:59:59')).toDate())-cht.x)
      .attr('cy', d=>{
        final.date = d.CDATE
        final.value = d[`${cols_}REMAIN_CUM`]
        return cht.scale(d[`${cols_}REMAIN_CUM`])
      })
      .attr('r', sch == 'PLAN' ? 2 : 2.5)
      .style('stroke', cht.color).style('stroke-width', sch == 'PLAN' ? 1 : 1.5)
      .style('fill', '#fff')

      // // Text Remain
      // let last = cht.data.at(-1)
      // lineChart
      // .append('text')
      // .attr('id', `${cols_}LAST_VALUE`)
      // .attr('x', this.timeline.scale(moment(moment(last.CDATE).format('YYYY-MM-DD 23:59:59')).toDate())-cht.x + 6)
      // .attr('y', cht.scale(last[`${cols_}REMAIN_CUM`]) + 3.5)
      // .attr('font-weight', 410)
      // .attr('font-size', 11).attr('fill', '#000').attr('text-anchor', 'start')
      // .text(last[`${cols_}REMAIN_CUM`])
    },

    
    last_Turnover() {
      
      let Turnover = this.Area_Canvas
      .append('g')
      .attr('transform', `translate(${this.canvas.padding.left+.5}, ${this.canvas.padding.top+.5})`)
      .attr('font-family', 'roboto') // rage / roboto

      let last_Turnover = moment(moment(this.Overall.PLAN_MAX).format('YYYY-MM-DD 23:59:59')).toDate()

      Turnover
      .append('line')
      .attr('x1', this.timeline.scale(last_Turnover)).attr('y1', 0)
      .attr('x2', this.timeline.scale(last_Turnover)).attr('y2', this.canvas.chart.height)
      .attr('stroke', '#757575').attr('stroke-width', .3)

      Turnover
      .append('line')
      .attr('x1', this.timeline.scale(last_Turnover)).attr('y1', 0)
      .attr('x2', this.timeline.scale(last_Turnover)-20).attr('y2', -20)
      .attr('stroke', '#757575').attr('stroke-width', .2)

      Turnover
      .append('rect')
      .attr('x', this.timeline.scale(last_Turnover)-120).attr('y', -31)
      .attr('width', 100).attr('height', 13).attr('fill', '#fff')
      .attr('stroke', '#757575').attr('stroke-width', .2)

      Turnover
      .append('text')
      .attr('x', this.timeline.scale(last_Turnover)-24).attr('y', -22)
      .attr('font-size', 9).attr('fill', '#757575').attr('text-anchor', 'end')
      .text('Last T/O Forecast Date')
    }

  }
}