
// SYSTEM COMPLETION -------------------------------------------------------------------------

import CR_Home                    from '../10_Crisp/SystemCompletion/Dashboard/Homepage/index.vue'

// Planning
import CR_PLAN_Generator          from '../10_Crisp/SystemCompletion/Planning/Plan_Generator/index.vue'
import CR_PLAN_Forecast           from '../10_Crisp/SystemCompletion/Planning/FC_Analysis/index.vue'

// DashBoard Elements
import CR_DBE_Header              from '../10_Crisp/SystemCompletion/Dashboard/Header/index.vue'
import CR_DBE_UnitSlide           from '../10_Crisp/SystemCompletion/Dashboard/Unit_Slide/index.vue'

// MC & PreCommissioning
import CR_Skyline_Subsys_Month    from '../10_Crisp/SystemCompletion/Completion/Skyline_Subsys_Month/index.vue'
import CR_Skyline_Subsys_GR       from '../10_Crisp/SystemCompletion/Completion/Skyline_Subsys_GR/index.vue'
import CR_Skyline_Subsys_GR_FC       from '../10_Crisp/SystemCompletion/Completion/Skyline_Subsys_GR_FC/index.vue'
import CR_Skyline_Subsys_GR_FC_Simple       from '../10_Crisp/SystemCompletion/Completion/Skyline_Subsys_GR_FC_Simple/index.vue'
import CR_Skyline_Subsys_GR_Mini  from '../10_Crisp/SystemCompletion/Completion/Skyline_Subsys_GR_Mini/index.vue'
import CR_Skyline_Subsys_ES       from '../10_Crisp/SystemCompletion/Completion/Skyline_Subsys_ES/index.vue'
import CR_Skyline_Subsys_ES_FC       from '../10_Crisp/SystemCompletion/Completion/Skyline_Subsys_ES_FC/index.vue'
import CR_SCurve_Turnover         from '../10_Crisp/SystemCompletion/Completion/SCurve_Turnover/index.vue'
import CR_SCurve_ITRs             from '../10_Crisp/SystemCompletion/Completion/SCurve_ITRs/index.vue'
import CR_Rundown_ITRs            from '../10_Crisp/SystemCompletion/Completion/Rundown_ITRs/index.vue'
import CR_Rundown_Punch           from '../10_Crisp/SystemCompletion/Completion/Rundown_Punch/index.vue'
import CR_Rundown_Punch_v2           from '../10_Crisp/SystemCompletion/Completion/Rundown_Punch_v2/index.vue'

// UNIT
import CR_Unit_Plotplan           from '../10_Crisp/SystemCompletion/Unit/Unit_Plotplan/index.vue'
import CR_Unit_Modal_Header       from '../10_Crisp/SystemCompletion/Unit/Unit_Modal_Header/index.vue'
import CR_Unit_Radial             from '../10_Crisp/SystemCompletion/Unit/Unit_Radial/index.vue'
import CR_Unit_Overall_Status     from '../10_Crisp/SystemCompletion/Unit/Unit_Overall_Status/index.vue'
import CR_Unit_Overall_Gantt      from '../10_Crisp/SystemCompletion/Unit/Unit_Overall_Gantt/index.vue'
import CR_Unit_Rundown_B_ITRs     from '../10_Crisp/SystemCompletion/Unit/Rundown_B_ITRs/index.vue'
import CR_Unit_Rundown_A_ITRs     from '../10_Crisp/SystemCompletion/Unit/Rundown_A_ITRs/index.vue'

// Milestone
import CR_MS_CSU_Icicle           from '../10_Crisp/SystemCompletion/Milestones/CSU_Icicle/index.vue'
import CR_MS_CSU_Skyline          from '../10_Crisp/SystemCompletion/Milestones/CSU_Skyline/index.vue'
import CR_MS_Contractor_Skyline   from '../10_Crisp/SystemCompletion/Milestones/Contractor_Skyline/index.vue'
import CR_MS_Contractor_Handover  from '../10_Crisp/SystemCompletion/Milestones/Contractor_Handover/index.vue'
import CR_MS_CSU_Handover         from '../10_Crisp/SystemCompletion/Milestones/CSU_Handover/index.vue'



export default {
  CR_Home,

  // Planning
  CR_PLAN_Generator,
  CR_PLAN_Forecast,

  // DashBoard Elements
  CR_DBE_Header,
  CR_DBE_UnitSlide,

  // Completion
  CR_Skyline_Subsys_Month,
  CR_Skyline_Subsys_GR,
  CR_Skyline_Subsys_GR_FC,
  CR_Skyline_Subsys_GR_FC_Simple,
  CR_Skyline_Subsys_GR_Mini,
  CR_Skyline_Subsys_ES,
  CR_Skyline_Subsys_ES_FC,
  CR_SCurve_Turnover,
  CR_SCurve_ITRs,
  CR_Rundown_ITRs,
  CR_Rundown_Punch,
  CR_Rundown_Punch_v2,

  // UNIT
  CR_Unit_Plotplan,
  CR_Unit_Modal_Header,
  CR_Unit_Radial,
  CR_Unit_Overall_Status,
  CR_Unit_Overall_Gantt,
  CR_Unit_Rundown_B_ITRs,
  CR_Unit_Rundown_A_ITRs,

  // Milestone
  CR_MS_CSU_Icicle,
  CR_MS_CSU_Skyline,
  CR_MS_Contractor_Skyline,
  CR_MS_Contractor_Handover,
  CR_MS_CSU_Handover,

}