import * as d3 from 'd3'

export default {
  methods: {

    header_Discipline() {

      this.disc_Sheets({
        x: 455,
        item:   'CP', 
        color:  '#83D2F5', 
        defs:   'LightBlue',
        title:  'Punch C',
      })

      this.disc_Sheets({
        x: 875,
        item:   'DP', 
        color:  '#d0d01e', 
        defs:   'Yellow',
        title:  'Punch D',
      })

    },


    disc_Sheets(s) {

      let Area_Discipline = this.Area_Canvas
      .append('g')
      .attr('transform',`translate(${s.x}, 10)`)
      .style('cursor', 'default')

      // Bar Chart
      this.head_BarCharts(Area_Discipline, {
        title:    s.title,
        item:     s.item,
        color:    s.color,
        bColor:   s.defs,
      })

      let colX = [90, 190, 225, 260, 263]

      let disc_Sheet = Area_Discipline
      .append('g')
      .attr('transform',`translate(30.5, 60)`)
      .style('font-family', 'roboto')
      
      let line = [
        {x1: 263, y1:-15, x2: 263},
        {x1: 313, y1:-15, x2: 313},
        {x1: 363, y1:-15, x2: 363},
      ]
      let eLine = this.Discipline.filter(f=> f.ITEM == s.item && f.DISC !== 'OV').length * 18 -15

      line.forEach(d=> {
      disc_Sheet
      .append('line')
      .attr('x1', d.x1).attr('y1', d.y1).attr('x2', d.x2).attr('y2', eLine).attr('stroke', '#bcbcbc').attr('stroke-width', 0.5)
      })

      disc_Sheet.append('text').attr('x', 263).attr('y', -17).attr('font-size', 7).attr('text-anchor', 'middle').attr('fill', '#757575').text('0%')
      disc_Sheet.append('text').attr('x', 313).attr('y', -17).attr('font-size', 7).attr('text-anchor', 'middle').attr('fill', '#757575').text('50%')
      disc_Sheet.append('text').attr('x', 363).attr('y', -17).attr('font-size', 7).attr('text-anchor', 'middle').attr('fill', '#757575').text('100%')

      this.Discipline.filter(f=> f.ITEM == s.item && f.DISC !== 'OV').forEach((d,i) => {
        d.checked = false

        // Check Box
        let check = disc_Sheet
        .append('g')
        .attr('id', `CHECK_GROUP_${s.item}_${d.DISC}`)
        .attr('transform',`translate(0, 0)`)
        .attr('cursor', 'pointer').attr('opacity', 1)
        .on('mouseover', function() {
          d3.select(this).attr('opacity', .7)
        })
        .on('mouseout', function() {
          d3.select(this).attr('opacity', 1)
        })
        .on('click', ()=> {
          this.reset_Discipline(s.item, d.DISC)
        })

        check
        .append('circle')
        .attr('transform',`translate(${80}, ${i*18 - 3})`)
        .attr('r', 4.5).attr('opacity', 1)
        .attr('fill', 'none').attr('stroke', '#333').attr('stroke-width', 1).attr('stroke-opacity', 0.5)

        check
        .append('circle')
        .attr('id', `DISC_${s.item}_${d.DISC}`)
        .attr('transform',`translate(${80}, ${i*18 - 3})`)
        .attr('r', 2.5).attr('opacity', 0)
        .attr('fill', '#333').attr('stroke-width', 0)

        // Dicsipline Title
        check
        .append('text')
        .attr('class', `DISC_${s.item}_${d.DISC}_ITEM`)
        .attr('x', colX[0]).attr('y', i*18)
        .attr('font-size', 9).attr('text-anchor', 'start').attr('fill', '#757575')
        .text(d.DISC_LONG.substr(2))

        // Total
        disc_Sheet
        .append('text')
        .attr('class', `DISC_${s.item}_${d.DISC}_ITEM`)
        .attr('x', colX[1]).attr('y', i*18).attr('font-size', 9).attr('text-anchor', 'end').attr('fill', '#757575')
        .attr('cursor', 'pointer')
        .text(d.TOT)
        .call(this.call_Punch, {item: s.item, type: 'TOT', disc: d.DISC})

        // Actual
        disc_Sheet
        .append('text')
        .attr('class', `DISC_${s.item}_${d.DISC}_ITEM`)
        .attr('x', colX[2]).attr('y', i*18).attr('font-size', 9).attr('text-anchor', 'end').attr('fill', '#757575')
        .attr('cursor', 'pointer')
        .text(d.ACT)
        .call(this.call_Punch, {item: s.item, type: 'ACT', disc: d.DISC})

        // Remain
        disc_Sheet
        .append('text')
        .attr('class', `DISC_${s.item}_${d.DISC}_ITEM`)
        .attr('x', colX[3]).attr('y', i*18).attr('font-size', 9).attr('text-anchor', 'end').attr('fill', '#757575')
        .attr('cursor', 'pointer')
        .text(d.REM)
        .call(this.call_Punch, {item: s.item, type: 'REM', disc: d.DISC})

        // background
        disc_Sheet
        .append('rect')
        .attr('x', colX[4]).attr('y', i*18-9).attr('width', 100).attr('height', 12)
        .attr('fill', '#bcbcbc').attr('opacity', .2)

        disc_Sheet
        .append('rect')
        .attr('x', colX[4]).attr('y', i*18-9).attr('width', d.PRO).attr('height', 12)
        .attr('fill', s.color).attr('opacity', .9)

        // Remain
        disc_Sheet
        .append('text')
        .attr('x', d.PRO > 60 ? colX[4] + d.PRO - 2 : colX[4] + d.PRO + 2)
        .attr('y', i*18).attr('font-size', 9)
        .attr('text-anchor', d.PRO > 60 ? 'end' : 'start')
        .attr('fill',  d.PRO > 60 ? '#fff' : '#757575')
        .text(d.PRO+'%')
      })

    },




  }
}