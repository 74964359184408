import * as d3 from 'd3'

export default {
  methods: {

    header_Sheets() {
      let Overall = this.Area_Canvas
      .append('g')
      .attr('transform',`translate(20, 90)`)
      .style('font-family', 'roboto').style('cursor', 'default')

      // C Check Box for Overall --------------------------------------------
      let Csheet = Overall
      .append('g')
      .attr('transform',`translate(0, 0)`)
      .attr('cursor', 'pointer').attr('opacity', 1)
      .on('mouseover', function() {
        d3.select(this).attr('opacity', .7)
      })

      .on('mouseout', function() {
        d3.select(this).attr('opacity', 1)
      })
      .on('click', ()=> {
        this.reset_Discipline('CP', 'OV')
      })
      Csheet
      .append('text')
      .attr('transform',`translate(25, 38)`)
      .attr('font-size', 10).attr('fill', '#000').attr('text-anchor', 'start')
      .text(`Punch C`)

      Csheet
      .append('circle')
      .attr('transform',`translate(16, 34)`)
      .attr('r', 5).attr('opacity', 1)
      .attr('fill', 'none').attr('stroke', '#333').attr('stroke-width', 1).attr('stroke-opacity', 0.5)

      Csheet
      .append('circle')
      .attr('id', `DISC_CP_OV`)
      .attr('transform',`translate(16, 34)`)
      .attr('r', 3).attr('opacity', this.Discipline[0].ITEM == 'CP' ? 1 : 0)
      .attr('fill', '#333').attr('stroke-width', 0)


      // D Check Box for Overall --------------------------------------------
      let Dsheet = Overall
      .append('g')
      .attr('id', `CHECK_GROUP_B_OV`)
      .attr('transform',`translate(220, 0)`)
      .attr('cursor', 'pointer').attr('opacity', 1)
      .on('mouseover', function() {
        d3.select(this).attr('opacity', .7)
      })
      .on('mouseout', function() {
        d3.select(this).attr('opacity', 1)
      })
      .on('click', ()=> {
        this.reset_Discipline('DP', 'OV')
      })
      Dsheet
      .append('text')
      .attr('transform',`translate(25, 38)`)
      .attr('font-size', 10).attr('fill', '#333').attr('text-anchor', 'start')
      .text(`Punch D`)

      Dsheet
      .append('circle')
      .attr('transform',`translate(16, 34)`)
      .attr('r', 5).attr('opacity', 1)
      .attr('fill', 'none').attr('stroke', '#333').attr('stroke-width', 1).attr('stroke-opacity', 0.5)

      Dsheet
      .append('circle')
      .attr('id', `DISC_DP_OV`)
      .attr('transform',`translate(16, 34)`)
      .attr('r', 3).attr('opacity',  this.Discipline[0].ITEM == 'DP' ? 1 : 0)
      .attr('fill', '#333').attr('stroke-width', 0)


      this.sheet_Summary(Overall, {
        x: 10,
        main: 'Punch C',
        sub: 'Status of Punch C',
        sht: 'CP',
        bColor: '#83D2F5',
      })

      this.sheet_Summary(Overall, {
        x: 225,
        main: 'Punch D',
        sub: 'Status of Punch D',
        sht: 'DP',
        bColor: '#d0d01e',
      })
    },


    sheet_Summary(selection, d) {

      let sheets = selection
      .append('g')
      .attr('transform',`translate(${d.x}, 0)`)
      .style('font-family', 'roboto').style('cursor', 'default')

      sheets
      .append('text')
      .attr('transform',`translate(0, 5)`).attr('font-size', 11).attr('fill', '#000').attr('text-anchor', 'start')
      .text(d.main)

      sheets
      .append('text')
      .attr('transform',`translate(0, 15)`).attr('font-size', 9).attr('fill', '#BDBCBC').attr('text-anchor', 'start')
      .text(d.sub)

      this.sheet_BarChart(selection, {x: d.x, y: 40, sht: d.sht, color: d.bColor})
    },


    sheet_BarChart(selection, d) {
      
      let sheets = selection
      .append('g')
      .attr('transform',`translate(${d.x+25}, ${d.y})`)

      sheets
      .append('text')
      .attr('class', `DISC_${d.sht}_${'OV'}_ITEM`)
      .attr('transform',`translate(38, 17)`)
      .attr('font-size', 10).attr('fill', '#333').attr('text-anchor', 'end')
      .attr('cursor', 'pointer')
      .text(this.Overall[`${d.sht}_TOT`])
      .call(this.call_Punch, {item: d.sht, type: 'TOT'})
      
      sheets
      .append('rect')
      .attr('x', 40).attr('y', 7.5).attr('width', 100).attr('height', 12)
      .attr('fill', '#bcbcbc').attr('opacity', .3)

      sheets
      .append('rect')
      .attr('x', 40).attr('y', 7.5)
      .attr('width', this.Overall[`${d.sht}_PRO`]).attr('height', 12)
      .attr('fill', d.color).attr('opacity', 1)

      let PRO = this.Overall[`${d.sht}_PRO`]
      sheets
      .append('text')
      .attr('class', `DISC_${d.sht}_${'OV'}_ITEM`)
      .attr('x',  PRO > 60 ? 38 + PRO : 42 + PRO)
      .attr('y', 17)
      .attr('font-size', 10).attr('fill', '#333')
      .attr('text-anchor', PRO > 60 ? 'end' : 'start')
      .attr('cursor', 'pointer')
      .text(this.Overall[`${d.sht}_ACT`])
      .call(this.call_Punch, {item: d.sht, type: 'ACT'})

      sheets
      .append('text')
      .attr('class', `DISC_${d.sht}_${'OV'}_ITEM`)
      .attr('transform',`translate(142, 17)`)
      .attr('font-size', 10).attr('fill', '#333').attr('text-anchor', 'start')
      .attr('cursor', 'pointer')
      .text(this.Overall[`${d.sht}_REM`])
      .call(this.call_Punch, {item: d.sht, type: 'REM'})
    },

  }
}